<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkInputText from "@components/input/AkInputText";
import LinkRow from "@views/link/components/LinkRow";
import AkDialog from "@components/general/AkDialog";
import AkCheckboxButton from "@components/input/AkCheckboxButton";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";
import utilsMixin from "@mixins/utilsMixin";
import trafficConditionConst from "@mixins/const/trafficConditionConst";
import roadwayStateConst from "@mixins/const/roadwayStateConst";
import snowStateConst from "@mixins/const/snowStateConst";
import eventMixin from "@mixins/eventMixin";
import dateFormatter from "@mixins/dateFormatter";
import linkTypeConst from "@mixins/const/linkTypeConst";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import linkSheetService from "@services/linkSheetService";
import linkRowService from "@services/linkRowService";
import rolePermissionService from "@services/rolePermissionService";
import AkButton from "@components/input/AkButton.vue";

export default {
  components: {AkButton, AkFormView, AkInputText, LinkRow, AkDialog, AkCheckboxButton},
  mixins: [randomRef, trafficConditionConst, roadwayStateConst, snowStateConst, eventMixin, dateFormatter, roleMixin, utilsMixin, linkTypeConst, typeVhConst],
  metaInfo() {
    return {
      title: "link_sheet.details",
    }
  },
  data() {
    return {
      type: null,
      ager: null,
      uer: null,
      cei: null,
      current: {},
      linkRows: [],
      events: [],
      validated: false,
      downloading: false
    }
  },
  mounted() {
    this.getRef().showLoader(this.$refs.form);

    let msg = this.$route.query.msg;
    if (msg && msg !== "") {
      this.getRef().success(msg);
    }

    let error = this.$route.query.error;
    if (error && error !== "") {
      this.getRef().error(error);
    }

    let p1 = linkSheetService.findById(this.$route.params.id);
    p1.then(data => {this.current = data;});

    let p2 = linkRowService.findAllByLinkSheetId(this.$route.params.id);
    p2.then(data => {
      this.linkRows = data;
      this.linkRows.sort((a,b) => {
       return a.circuitNumber.localeCompare(b.circuitNumber);
      });
    });
  
    let p3 = rolePermissionService.authorizeAccessByPermissions([
      this.permissionConst.link.view,
      this.permissionConst.link.viewOther,
      this.permissionConst.link.viewValidated,
      this.permissionConst.link.viewValidatedOther,
      this.permissionConst.link.viewArchived,
      this.permissionConst.link.viewArchivedOther
    ], 'or');

    Promise.all([p1, p2, p3]).then(()=>{
      this.events = this.getSheetEvents(this.current);
      this.initData();
      this.getRef().hideLoader();
    });
  },
  methods: {
    initData() {
      this.date = this.formatDate(this.current.date);
      this.type = this.displayForLinkTypeConst(this.current.type);
      this.ager = this.linkRows[0].agerLabel;
      this.uer = this.linkRows[0].uerLabel;
      this.cei = this.linkRows[0].ceiLabel;
    },
    downloadSheet() {
      this.downloading = true;
     linkSheetService.download(this.current).finally(() => {
        this.downloading = false;
      });
    },
    validateSheet() {
      this.getRef().resetMessages();
      let linkSheetDTO = {
        linkSheet: this.current,
        linkRows: this.linkRows,
        validated: true
      }
      linkSheetService.update(linkSheetDTO).then(data => {
        this.current = data.linkSheet;
        this.events = this.getSheetEvents(this.current);
        this.getRef().success(this.$t("link_sheet.validated"));
        this.validated = true;
      }).catch(e => {
        this.getRef().error(this.$t("error."+e.response.data.message));
      });
    },
    openDialogValidate() {
      this.$refs.dialogValidate.show();
    },
    isAlerte() {
      let tempSolPositiveNb = this.linkRows.filter(row => row.groundTemp !== null && row.groundTemp > 0);
      let drySaltTonnageNb = this.linkRows.filter(row => row.drySaltTonnage !== null && row.drySaltTonnage > 0);

      if (tempSolPositiveNb.length > 0 && drySaltTonnageNb.length > 0) {
        return true;
      }
    },
  },
  computed: {
    period() {
      if (this.current.night) return this.$t('period_night', [
        this.formatDate(this.current.date), this.formatDate(this.addDays(this.current.date, 1))
      ]);
      else return this.$t('period_day', [this.formatDate(this.current.date)]);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('link_sheet.details')">
    <template v-slot:action>
      <RouterLink v-if="this.current.id !== undefined && canEditLink(current) && !this.current.archived"
        :to="{ name: 'linkUpdate', params:{id:this.current.id}}"
        class="btn btn-inverse-primary btn-xs">
        <i class="fe fe-edit pr-1"/>{{ $t('link_sheet.update_this') }}
      </RouterLink>
      <AkButton v-if="null !== current.dateValidated"
                class-name="btn btn-inverse-primary btn-xs ml-1"
                @click="downloadSheet"
                :loading="downloading">
        <i class="fe fe-download pr-1"/>{{ $t('link_sheet.download') }}
      </AkButton>

    </template>
    <template v-slot:form>
      <div class="form-row">
        <AkInputText :disabled=true v-model="period" class-name="col-md-4" :label="$t('date')"/>
        <AkInputText :disabled=true v-model="type" class-name="col-md-4" :label="$t('link_sheet.type')"/>
      </div>
      <div class="form-row">
        <AkInputText :disabled=true v-model="ager" class-name="col-md-4" :label="$t('ager_label')"/>
        <AkInputText :disabled=true v-model="uer" class-name="col-md-4" :label="$t('uer_label')"/>
        <AkInputText :disabled=true v-model="cei" class-name="col-md-4" :label="$t('cei_label')"/>
      </div>
      <div class="form-row" v-if="current.typeVh">
        <AkCheckboxButton :disabled="true" v-model="current.typeVh" class-name="col-md-6" :label="$t('type_vh_label')" :multiple="false" :options=typeVhConst />
      </div>
      <h5>{{ $t('link_sheet.circuits') }}</h5>
      <div class="mt-2 row">
        <LinkRow v-for="(row, index) in linkRows" :key="index" :row="row" :index="index" ref="panels" :disabled="true"/>
      </div>
      <h5>{{ $t('link_sheet.history') }}</h5>
      <div class="form-row">
        <Timeline :value="events">
          <template #opposite="slotProps">
            <small class="p-text-secondary">{{slotProps.item.dateDisplay}}</small>
          </template>
          <template #content="slotProps">
            {{slotProps.item.name}}
          </template>
        </Timeline>
      </div>
      <button v-if="canValidateLink(current) && !validated" 
      @click=openDialogValidate() 
      class="btn btn-success float-right">{{ $t('validate') }}</button>
    </template>
    
    <template v-slot:extra>
      <AkDialog
        ref="dialogValidate"
        :auto-hide-on-validate="true"
        :cancel-label="$t('no')"
        :validate-label="$t('yes')"
        :title="$t('link_sheet.validate_dialog')"
        width="450px"
        @validate="this.validateSheet()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
          <span v-if="!isAlerte()">{{ $t('link_sheet.confirm_validation') }}</span>
          <span style="color: red;" v-if="isAlerte()">{{ $t('link_sheet.confirm_validation_alerte') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormView>
</template>